'use client';
import { IC_EXPRESS, IC_REGULAR } from '@/assets/icons';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import AddToCart2 from '@/components/molecules/AddToCart2';
import PrescriptionDialog from '@/components/molecules/ProductDialogs';
import { useAlert } from '@/contexts/AlertProvider';
import { shimmer, toBase64 } from '@/services/ecommerce/utils/LoadingImage';
import { generateProductTitle } from '@/services/ecommerce/utils/MetaGenerator';
import {
	ProductSummary,
	checkDeliveryAndStock,
	generateProductLink,
	getProductImage
} from '@/services/ecommerce/utils/helper';
import { imageLoader } from '@/services/ecommerce/utils/imageLoader';
import { Product } from '@/services/ecommerce/types/productTypes';
import { debounce, get, memoize } from 'lodash';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import styles from './product-card.module.scss';

interface ProdcutCardProps {
	data: Product;
	showInfo?: boolean;
	style?: any;
}

const ProductCard = ({ data, showInfo = false, style }: ProdcutCardProps) => {
	const { p_name, p_strength, pv } = data;
	const [imgLoadError, setImgLoadError] = useState(false);
	const debouncedSetImgLoadError = debounce(() => setImgLoadError(true), 200);
	const memoizedProductSummary = memoize((pv) => {
		return ProductSummary(pv);
	});
	const alert = useAlert();

	const { mrp, price, variant, discountPercentage } = memoizedProductSummary(get(pv, '[0]', {}));

	const { stock, isExpress, delevery, description, short_d_text } = checkDeliveryAndStock(data, variant);
	return (
		<div
			className={styles.product_wrap}
			style={{
				...style
			}}>
			<div className={`${styles.product} `}>
				{showInfo && (
					<div
						className='d-flex'
						style={{
							alignItems: 'end',
							display: 'flex',
							justifyContent: 'end',
							position: 'absolute',
							right: '10px',
							zIndex: 1,
							marginTop: '10px'
						}}>
						{!!data?.p_rx_req && <PrescriptionDialog />}
					</div>
				)}

				<div
					style={{
						position: 'relative'
					}}>
					<Link
						href={generateProductLink(data, variant)}
						style={{
							...(!stock && {
								opacity: 0.5
							})
						}}>
						<div className={styles.img_wrap}>
							<Image
								loader={imageLoader}
								unoptimized={true}
								placeholder='blur'
								blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(200, 200))}`}
								width={200}
								height={200}
								src={getProductImage(data)}
								alt={generateProductTitle(data)}
								onError={debouncedSetImgLoadError}
								style={{
									width: 'auto',
									objectFit: 'contain',
									aspectRatio: 1
								}}
							/>
							{price !== mrp && (
								<span className={styles.discount}>
									<span>{discountPercentage}% OFF</span>
								</span>
							)}
						</div>
					</Link>
					{!stock && (
						<div className={styles.outofstock}>
							<div className={styles.stocks_message}>Out of stock</div>
						</div>
					)}
				</div>
				<div className={`${styles.product_details} `}>
					{stock ? (
						<Tooltip
							style={{
								backgroundColor: 'white',
								color: 'black',
								fontSize: '12px',
								padding: '10px',
								borderRadius: '10px',
								border: '1px solid #ccc',
								boxShadow: '0px 0px 10px 0px #ccc',
								maxWidth: '200px',
								zIndex: 1
							}}
							content={
								<>
									<div className='fw-700'>{delevery}</div>
									<div
										style={{
											fontSize: '12px',
											marginTop: '5px'
										}}>
										{description}
									</div>
								</>
							}>
							<div className={styles.delevery_info}>
								{!isExpress ? <IC_REGULAR /> : <IC_EXPRESS />}
								<div>{short_d_text}</div>
							</div>
						</Tooltip>
					) : (
						<div className='mt-30'></div>
					)}
					<h3 className={styles.product_title + ' swiper-no-swiping'}>
						<Link href={generateProductLink(data, variant)}>
							<div>
								{p_name}

								<span className='ml-5'>{p_strength}</span>
							</div>
						</Link>
					</h3>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							gap: '10px'
						}}>
						<div className={styles.price + ' swiper-no-swiping'}>
							{price !== mrp ? <del>৳{mrp}</del> : <div className='h-21'></div>}
							<p className={styles.price_text_style}>৳{price}</p>
						</div>
						<AddToCart2
							product={data}
							variant={get(pv, '[0]')}
							height='34px'
							inputStyle={{
								minWidth: 'auto',
								maxWidth: 'auto',
								width: 50,
								height: 34
							}}
							buttonStyle={{
								minWidth: 'auto',
								padding: '10px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								fontSize: 14
							}}
							label={<span className='p-0'>ADD</span>}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductCard;
