'use client';
import { Block } from '@/services/ecommerce/types/blocksTypes';
import React from 'react';
import 'swiper/css/pagination';

interface Props {
	data: Block;
	sl?: any;
}

const TextBlock: React.FC<Props> = ({ data }) => {
	return <OthersImage data={data} />;
};

export default TextBlock;
const OthersImage = ({ data }: Props) => {
	const { block_data } = data;
	return (
		<div
			style={{
				position: 'relative'
			}}>
			<div
				className='overlay-content'
				style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					textAlign: 'center',
					color: 'white'
				}}>
				sdff
			</div>
		</div>
	);
};
