'use client';

import { generateProductLink, getProductImage, ProductSummary } from '@/services/ecommerce/utils/helper';
import { generateProductTitle } from '@/services/ecommerce/utils/MetaGenerator';
import { Product } from '@/services/ecommerce/types/productTypes';
import { get } from 'lodash';
import Link from 'next/link';
import AddToCart from '../../molecules/AddToCart';
import Img from '../../molecules/img';
import styles from './product-card.module.scss';

interface ProductCardProps {
	data: Product;
}

const ProductCardHorizontal = ({ data }: ProductCardProps) => {
	const { p_name, p_strength, pv } = data;
	const { mrp, price, variant, discountPercentage, pv_stock_status } = ProductSummary(get(pv, '[0]', {}));

	return (
		<div className={styles.product_wrap_horizontal}>
			<div className={`${styles.productHorizontal} `}>
				<Link href={generateProductLink(data, variant)}>
					<div className={styles.img_wrap}>
						<Img alt={generateProductTitle(data)} src={getProductImage(data)} />
						{price !== mrp && <span style={{ marginBottom: '20px' }}>{discountPercentage} % OFF</span>}
					</div>
				</Link>
				<div className={`${styles.product_details} `}>
					<h3 className={styles.product_title}>
						<Link href={generateProductLink(data, variant)}>
							{p_name} <span> {p_strength}</span>
						</Link>
					</h3>
					<div className={`${styles.price} swiper-no-swiping`}>
						{price !== mrp ? <del>৳{mrp}</del> : <div className='h-21'></div>}
						<h4 className='mt-10'>৳{price}</h4>
					</div>
					<div
						className='mt-35'
						style={{
							position: 'absolute',
							bottom: '10px'
						}}>
						<AddToCart
							product={data}
							productRawData={data}
							variant={get(pv, '[0]')}
							inputStyle={{
								minWidth: 'auto'
							}}
							label={<span className='p-14'>Add to Cart</span>}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductCardHorizontal;
